import React, { Component } from 'react';

import { styled } from 'utils/emotion';
import media from 'utils/mediaqueries';

import Image from 'components/Image';
import Headline from 'components/Headline';
import IconArrowLink from 'components/Svg/IconArrowLink';

const EmployeeContainer = styled('div')({
  outline: 'none',
  width: '100%',
  position: 'relative',
  height: '100%',
  textAlign: 'center',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-end',

  img: {
    width: 'auto',
    maxHeight: '100%',
    margin: '0 auto',
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    paddingTop: 55,
  },

  '.headline': {
    display: 'none',
    color: 'white',
  },
});

export const NameButton = styled('button')({
  fontSize: 14,
  fontWeight: '600',
  position: 'relative',
  zIndex: '1',
  bottom: 55,
  color: 'white',
  textAlign: 'center',
  width: '100%',
  cursor: 'pointer',
  outline: 'none',
  '&:hover, &:focus': {
    color: 'white',
    [media('md')]: {
      textDecoration: 'underline',
    },
  },
  svg: {
    width: 20,
    height: 20,
    marginLeft: 5,
    fill: 'currentcolor',
    display: 'inline-block',
    verticalAlign: 'middle',
  },
});

export default class employee extends Component {
  render() {
    const { employee, index, employeeNameClick } = this.props;

    return (
      <EmployeeContainer className="employeeContainer">
        <Headline className="headline" tag="h3" brush={{ color: 'white' }}>
          {employee.employeeName}
        </Headline>
        {employee.employeeImage[0] && (
          <Image src={employee.employeeImage[0].url} />
        )}
        <NameButton
          className="nameButton"
          id={index}
          onClick={employeeNameClick}
        >
          {employee.employeeName}
          <IconArrowLink />
        </NameButton>
      </EmployeeContainer>
    );
  }
}
