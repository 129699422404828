import React, { Component } from 'react';
import { graphql } from 'gatsby';
import scrollToElement from 'scroll-to-element';
import SliderFlickity from 'components/SliderFlickity';
import theme from 'components/Layout/themeDefault';
import { bgGradientBlueLight } from 'components/Gradients';

import ProfileCard from './ProfileCard';
import Person from './Person';
import {
  SliderSection,
  Slide,
  employeeProfileOpenStyle,
  ButtonBackStyled,
} from './styles';

export default class TeamSlider extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isMobile: false,
      isEmployeeProfileOpenStyle: false,
      slidePositionOnOpen: 0,
    };
  }

  componentWillMount() {
    if (typeof window !== 'undefined') {
      this.setMobileState();
      window.addEventListener('resize', this.handleWindowSizeChange);
    }
  }

  componentDidUpdate = () => {
    this?.teamSliderResize();
  };

  setMobileState() {
    if (window.innerWidth < theme.breakpoints.lg) {
      this.setState({
        isMobile: true,
      });
    } else {
      this.setState({
        isMobile: false,
      });
    }
  }

  handleWindowSizeChange = () => {
    this.setMobileState();
  };

  handleEmployeeNameClick = e => {
    const { isEmployeeProfileOpenStyle } = this.state;
    if (!isEmployeeProfileOpenStyle) {
      this.openEmployeeProfile();
      this.teamSlider.select(e.target.id, false, false);
    }
  };

  handleEmployeeProfileCloseClick = () => {
    const { slidePositionOnOpen } = this.state;
    this.teamSlider.select(slidePositionOnOpen, false, false);
    this.closeEmployeeProfile();
  };

  teamSliderResize = () => {
    this?.teamSlider?.resize();
  };

  setTeamSliderRef = ref => {
    this.teamSlider = ref;
  };

  closeEmployeeProfile() {
    this.setState({
      isEmployeeProfileOpenStyle: false,
    });
  }

  openEmployeeProfile() {
    this.setState({
      slidePositionOnOpen: this.teamSlider.selectedIndex,
    });
    this.scrollSliderIntoViewport();
    this.setState({
      isEmployeeProfileOpenStyle: true,
    });
  }

  scrollSliderIntoViewport() {
    const { id } = this.props;

    scrollToElement(`#${id}`, {
      align: 'top',
      duration: 200,
      offset: 0,
    });
  }

  render() {
    const { teamMembers, id } = this?.props?.pageBuilder;
    const { isEmployeeProfileOpenStyle, isMobile } = this.state;

    const flickityOptions = {
      contain: true,
      wrapAround: teamMembers.length > 5,
      pageDots: false,
      adaptiveHeight: true,
      initialIndex: 0,
      cellAlign: isMobile ? 'center' : 'center',
      imagesLoaded: true,
      prevNextButtons: isMobile
        ? teamMembers.length > 2
        : teamMembers.length > 5,
    };

    if (!teamMembers.length) {
      return null;
    }

    return (
      <SliderSection
        id={id}
        className={`${bgGradientBlueLight} ${
          isEmployeeProfileOpenStyle ? employeeProfileOpenStyle : ''
        }`}
      >
        {isEmployeeProfileOpenStyle && (
          <ButtonBackStyled onClick={this.handleEmployeeProfileCloseClick} />
        )}
        <SliderFlickity
          options={flickityOptions}
          flickityRef={r => this.setTeamSliderRef(r)}
        >
          {teamMembers.map((employee, index) => (
            <Slide key={employee.id}>
              <Person
                index={index}
                employee={employee}
                employeeNameClick={this.handleEmployeeNameClick}
              />
              {isEmployeeProfileOpenStyle && (
                <ProfileCard
                  employee={employee}
                  teamSliderResize={this?.teamSliderResize}
                />
              )}
            </Slide>
          ))}
        </SliderFlickity>
      </SliderSection>
    );
  }
}

export const query = graphql`
  fragment PageBuilderTeamSliderQuery on Craft_PageBuilderTeamSlider {
    teamMembers {
      id
      ... on Craft_Employees {
        employeeName
        employeeImage {
          url
          full: url(crop: { height: 1000, quality: 100 })
        }
        employeeVita {
          content
        }
        employeePublications {
          content
        }
      }
    }
  }
`;
