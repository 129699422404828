import React, { Component } from 'react';
import { graphql } from 'gatsby';

import * as pageBuilderComponents from 'components/PageBuilderComponents';

const componentNamePattern = 'Craft_PageBuilder';

function getComponent(__typename) {
  const componentName = __typename.replace(componentNamePattern, '');
  return pageBuilderComponents[componentName] || false;
}

export default class PageBuilder extends Component {
  render() {
    const { pageBuilder } = this.props;

    return (
      <>
        {pageBuilder.map((el, index) => {
          const PageBuilderComponent = getComponent(el.__typename);
          return PageBuilderComponent ? (
            <PageBuilderComponent
              id={`section-${index + 2}`}
              pageBuilder={el}
              key={el.__typename + el.id}
            />
          ) : null;
        })}
      </>
    );
  }
}

export const query = graphql`
  fragment PageBuilderQuery on Craft_PageBuilderUnion {
    __typename

    ... on Craft_PageBuilderText {
      id
      ...PageBuilderTextQuery
    }

    ... on Craft_PageBuilderImageText {
      id
      ...PageBuilderImageTextQuery
    }

    ... on Craft_PageBuilderTeamSlider {
      id
      ...PageBuilderTeamSliderQuery
    }

    ... on Craft_PageBuilderHero {
      id
      ...PageBuilderHeroQuery
    }

    ... on Craft_PageBuilderModule {
      id
      ...PageBuilderModuleQuery
    }

    ... on Craft_PageBuilderSectionDownloads {
      id
      ...PageBuilderSectionDownloadsQuery
    }

    ... on Craft_PageBuilderSectionLogos {
      id
      ...PageBuilderSectionLogosQuery
    }

    ... on Craft_PageBuilderSectionContact {
      id
      ...PageBuilderSectionContactQuery
    }
  }
`;
